import React, {useState, useEffect} from 'react';
import {useAuth} from '../contexts/AuthProvider';
import {useNavigate} from 'react-router-dom';
import Email from '@mui/icons-material/MailOutline';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {TextField, InputAdornment, Button, Typography} from '@mui/material';
import './Login.css';
import logo from '../images/logo.svg';
import {formatCpf} from "./utils/MaskUtils";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorUsername, setErrorUsername] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const {login, userRole} = useAuth();
    const {userToken} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (userToken) {
            if (userRole === 'USER') {
                window.location.href = '/chat/new';

            } else {
                navigate('/dashboard');
            }
        }
    }, [userToken, userRole, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorUsername('');
        setErrorPassword('');

        if (!username) {
            setErrorUsername('O cpf é obrigatório.');
        }
        if (!password) {
            setErrorPassword('A senha é obrigatória.');
        }

        if (!username || !password) {
            return;
        }
        const cpfWithoutFormatting = username.replace(/\D/g, '');
        const success = await login(cpfWithoutFormatting, password);
        if (!success) {
            setErrorUsername('Credenciais inválidas. Tente novamente.');
            setErrorPassword('');
        }

    };
    const handleCpfChange = (e) => {
        let cpfValue = e.target.value.replace(/\D/g, '');

        if (cpfValue.length > 11) {
            cpfValue = cpfValue.slice(0, 11);
        }

        setUsername(formatCpf(cpfValue));
    };

    return (
        <div className="login-container">
            <div className="card">
                <img className="assistant-logo mb-2" src={logo} alt="Logo"/>
                <p className="welcome-msg">Boas vindas ao Assistente Virtual Hospitalar</p>
                <p className="form-msg">
                    Ainda não tem conta? Faça seu <a href="/" className="new-user-link">cadastro</a>
                </p>

                <form className="form" onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                        <label className="label" htmlFor="username">CPF</label>
                        <TextField
                            fullWidth
                            id="username"
                            variant="outlined"
                            value={username}
                            onChange={handleCpfChange}
                            placeholder="CPF"
                            className='form-control'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email fontSize="small" sx={{color: '#B3B3B3'}}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {errorUsername &&
                            <Typography color="error" className="error-message">{errorUsername}</Typography>}
                    </div>
                    <div className="form-group mb-3">
                        <label className="label" htmlFor="password">Senha</label>
                        <TextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Senha"
                            className='form-control'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <KeyIcon fontSize="small" sx={{color: '#B3B3B3'}}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            onClick={() => setShowPassword(!showPassword)}
                                            style={{
                                                padding: 0,
                                                minWidth: 'auto',
                                                background: 'transparent',
                                                boxShadow: 'none',
                                                color: 'rgba(0, 0, 0, 0.54)'
                                            }}
                                        >
                                            {showPassword ?
                                                <VisibilityOff sx={{fontSize: '18px'}}/> :
                                                <Visibility sx={{fontSize: '18px'}}/>
                                            }
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {errorPassword &&
                            <Typography color="error" className="error-message">{errorPassword}</Typography>}
                    </div>
                    <a href="/" className="forgot-password">Esqueci a senha</a>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        className="btn mt-3"
                        sx={{backgroundColor: '#1588C9', color: 'white'}}
                    >
                        Entrar
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Login;
