import React, { useState, useEffect } from "react";
import "./MessageFeedback.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import CloseIcon from "@mui/icons-material/Close";
import ChatApi from "../services/chatAPIService";
import { useAuth } from "../contexts/AuthProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

function MessageFeedback({ messageId, messageFeedback }) {
  const [copied, setCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [customFeedback, setCustomFeedback] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState(false); // Estado para controlar animação do sucesso

  const { registerFeedback } = ChatApi(useAuth().userToken);

  useEffect(() => {
    if (messageFeedback) {
      setSelectedOption(messageFeedback.feedback);
      if (
        messageFeedback.feedback !== "Resposta incorreta" &&
        messageFeedback.feedback !== "Não seguiu as instruções por completo"
      ) {
        setCustomFeedback(messageFeedback.feedback);
        setSelectedOption("Outro");
      }
    }
  }, [messageFeedback]);

  const handleCopy = () => {
    const messageElement = document.getElementById("message" + messageId);
    if (messageElement) {
      navigator.clipboard.writeText(messageElement.innerText);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    }
  };

  const handleClick = async (feedback) => {
    if (selectedOption) return;

    if (feedback === "Outro") {
      setShowModal(true);
      return;
    }

    try {
      await registerFeedback(messageId, feedback, false);
      setSelectedOption(feedback);
      setErrorMessage("");
      setFeedbackSuccess(true); 
      setTimeout(() => setFeedbackSuccess(false), 1000); 
      closeFeedbackOption();

      
    } catch (error) {
      setErrorMessage("Erro ao salvar feedback. Tente novamente.");
      console.error("Erro ao registrar feedback:", error);
    }
  };

  const handleCustomFeedbackSubmit = async () => {
    if (!customFeedback.trim()) return;
    await handleClick(customFeedback);
    setShowModal(false);
  };

  const openFeedbackOption = () => {
    if (selectedOption || messageFeedback !== null) return;

    document
      .getElementById("feedback-options-container" + messageId)
      .classList.add("open");
    document
      .getElementById("feedback-options-container" + messageId)
      .classList.remove("closed");
  };

  const closeFeedbackOption = () => {
    document
      .getElementById("feedback-options-container" + messageId)
      .classList.add("closed");
    document
      .getElementById("feedback-options-container" + messageId)
      .classList.remove("open");
  };

  return (
    <div className="feedback-container">
      <div className="feedback-icons-container">
        <div
          className={`feedback-icon ${copied ? "copied" : ""}`}
          onClick={handleCopy}
        >
          {copied ? <CheckIcon /> : <ContentCopyIcon />}
        </div>
        <div
          className={`feedback-icon ${feedbackSuccess ? "success-animation" : ""}`} // Animação quando o feedback for salvo
          onClick={openFeedbackOption}
          title={selectedOption ? selectedOption : "Clique para adicionar feedback"} // Exibe o conteúdo do feedback no tooltip
        >
          {!selectedOption ? (
            <ThumbDownOffAltIcon />
          ) : (
            <ThumbDownAltIcon />
          )}
        </div>
      </div>
      <div
        id={"feedback-options-container" + messageId}
        className="feedback-options-container closed card"
      >
        <div className="feedback-options-container-title">
          <div className="feedback-title">
            Explique melhor o motivo da insatisfação
          </div>
          <div onClick={closeFeedbackOption}>
            <CloseIcon />
          </div>
        </div>
        <div className="options">
          <div
            className={`option ${selectedOption === "Resposta incorreta" ? "selected" : ""}`}
            onClick={() => handleClick("Resposta incorreta")}
          >
            Resposta incorreta
          </div>
          <div
            className={`option ${selectedOption === "Não seguiu as instruções por completo" ? "selected" : ""}`}
            onClick={() => handleClick("Não seguiu as instruções por completo")}
          >
            Não seguiu as instruções por completo
          </div>
          <div
            className={`option ${selectedOption === "Outro" ? "selected" : ""}`}
            onClick={() => handleClick("Outro")}
          >
            Outro
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>

      {/* Modal Bootstrap */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Explique o motivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Detalhe sua insatisfação"
            value={customFeedback}
            id="feedback-input"
            onChange={(e) => setCustomFeedback(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-button-container">
            <Button
              className="modal-button"
              onClick={() => setShowModal(false)}
            >
              Cancelar
            </Button>
            <Button
              className="modal-button send-btn"
              onClick={handleCustomFeedbackSubmit}
            >
              Enviar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MessageFeedback;
