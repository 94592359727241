import React from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../Sidebar';
import PatientDashboard from '../dashboard/PatientDashboard';


function PatientDashboardRoute() {
  const { patientId } = useParams();

  return (
    <div className="app">
      <Sidebar />
      <div className="main-content">          
          <PatientDashboard patientId={ patientId }></PatientDashboard>
      </div>
    </div>
  );
}

export default PatientDashboardRoute;
