import React, { useState } from "react";
import History from "./History";
import MenuIcon from "@mui/icons-material/Menu";
import ChatIcon from "@mui/icons-material/Chat";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import HotelIcon from "@mui/icons-material/Hotel";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleIcon from '@mui/icons-material/People';
import { useAuth } from "../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

import "./Sidebar.css";

function Sidebar() {
  const { logout, userRole } = useAuth();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const chatOptions = [
    {
      key: "gestao_leitos",
      label: "Gestão de Leitos",
      icon: <HotelIcon />,
    },
    {
      key: "resumo_paciente",
      label: "Resumo do Paciente",
      icon: <AssignmentIndIcon />,
    },
    {
      key: "taxa_ocupacao_leitos",
      label: "Taxa de Ocupação de Leitos",
      icon: <NightShelterIcon />,
    },
    {
      key: "duvidas_gerais",
      label: "Dúvidas Gerais",
      icon: <LiveHelpIcon />,
    },
  ];

  const handleSuggestionClick = (contextKey) => {
    window.location.href = `/chat/new?context=${contextKey}`;
  };

  const renderAdminMenu = () => (
    <>
        <div>
            <div className="menu-icon" title="Menu" onClick={toggleSidebar}>
                <Tooltip title="Menu">
                    <MenuIcon style={{cursor: "pointer"}}/>
                </Tooltip>
                {isExpanded && <span className="menu-label"></span>} {/* Legenda */}
            </div>

            <div className="menu-icon" onClick={handleNavigation("/dashboard")}>
                <Tooltip title="Dashboard">
                    <DashboardIcon style={{cursor: "pointer"}}/>
                </Tooltip>
                {isExpanded && <span className="menu-label">Dashboard</span>} {/* Legenda */}
            </div>

            <a className="menu-icon" title="Novo Chat" href="/chat/new">
                <Tooltip title="Novo Chat">
                    <ChatIcon style={{cursor: "pointer"}}/>
                </Tooltip>
                {isExpanded && <span className="menu-label">Novo Chat</span>} {/* Legenda */}
            </a>

            {chatOptions.map((option) => (
                <div
                    key={option.key}
                    className="menu-icon"
                    onClick={() => handleSuggestionClick(option.key)}
                >
                    <Tooltip title={option.label}>
                        {React.cloneElement(option.icon, {
                            style: {cursor: "pointer"},
                        })}
                    </Tooltip>
                    {isExpanded && <span className="menu-label">{option.label}</span>} {/* Legenda */}
                </div>
            ))}
            <div className="menu-icon" onClick={handleNavigation("/user/list")}>
                <Tooltip title="Usuários">
                    <PeopleIcon style={{cursor: "pointer"}}/>
                </Tooltip>
                {isExpanded && <span className="menu-label">Usuários</span>} {/* Legenda */}
            </div>
        </div>

        {isExpanded && (
            <div className="history-container mt-3">
                <History/>
            </div>
        )}

        <div className="new-chat">
            {!isExpanded ? (
                <div className="menu-icon" title="Sair" onClick={logout}>
                    <Tooltip title="Sair">
                    <LogoutIcon style={{ cursor: "pointer" }} />
            </Tooltip>
            {isExpanded && <span className="menu-label">Sair</span>} {/* Legenda */}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );

  const renderUserMenu = () => (
    <>
      <div>
        <div className="menu-icon" title="Menu" onClick={toggleSidebar}>
          <Tooltip title="Menu">
            <MenuIcon style={{ cursor: "pointer" }} />
          </Tooltip>
          {isExpanded && <span className="menu-label"></span>} {/* Legenda */}
        </div>

        <a className="menu-icon" title="Novo Chat" href="/chat/new">
          <Tooltip title="Novo Chat">
            <ChatIcon style={{ cursor: "pointer" }} />
          </Tooltip>
          {isExpanded && <span className="menu-label">Novo Chat</span>} {/* Legenda */}
        </a>

        {chatOptions.map((option) => (
          <div
            key={option.key}
            className="menu-icon"
            onClick={() => handleSuggestionClick(option.key)}
          >
            <Tooltip title={option.label}>
              {React.cloneElement(option.icon, {
                style: { cursor: "pointer" },
              })}
            </Tooltip>
            {isExpanded && <span className="menu-label">{option.label}</span>} {/* Legenda */}
          </div>
        ))}
      </div>

      {isExpanded && (
        <div className="history-container mt-3">
          <History />
        </div>
      )}

      <div className="new-chat">
        {!isExpanded ? (
          <div className="menu-icon" title="Sair" onClick={logout}>
            <Tooltip title="Sair">
              <LogoutIcon style={{ cursor: "pointer" }} />
            </Tooltip>
            {isExpanded && <span className="menu-label">Sair</span>} {/* Legenda */}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );

  const renderManagerMenu = () => (
    <>
      <div>
        <div className="menu-icon" title="Menu" onClick={toggleSidebar}>
            <Tooltip title="Menu">
                <MenuIcon style={{cursor: "pointer"}}/>
            </Tooltip>
            {isExpanded && <span className="menu-label"></span>} {/* Legenda */}
        </div>
        <div
          className="menu-icon"
          title="Dashboard"
          onClick={handleNavigation("/dashboard")}
        >
          <Tooltip title="Dashboard">
            <DashboardIcon style={{ cursor: "pointer" }} />
          </Tooltip>
          {isExpanded && <span className="menu-label">Dashboard</span>} {/* Legenda */}
        </div>
      </div>
      <div className="new-chat">
        <div className="menu-icon" title="Sair" onClick={logout}>
          <Tooltip title="Sair">
            <LogoutIcon style={{ cursor: "pointer" }} />
          </Tooltip>
          {isExpanded && <span className="menu-label">Sair</span>} {/* Legenda */}
        </div>
      </div>
    </>
  );

  return (
    <div className={`sidebar ${isExpanded ? "expanded" : ""}`}>
      {userRole === "ADMIN" && renderAdminMenu()}
      {userRole === "USER" && renderUserMenu()}
      {userRole === "MANAGER" && renderManagerMenu()}
    </div>
  );
}

export default Sidebar;
