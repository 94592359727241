import React, { useState } from 'react';
import './ChatbotInputBox.css';
import send from "../images/chatbot/send.svg";

function ChatbotInputBox({ loadingAnswer, handleChatSubmit }) {
    const [input, setInput] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!loadingAnswer) {
                handleSubmit(e);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setInput('');
        await handleChatSubmit(input);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='input-box-container'>
                <div className="input-container">
                    <textarea
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        rows="3"
                    />
                </div>
                <button
                    type="submit"
                    className="send-button"
                    disabled={loadingAnswer}
                >
                    <img src={send} alt="Enviar" width="24" height="24" />
                </button>
            </div>
        </form>
    );
}

export default ChatbotInputBox;
