import React from "react";
import userIcon from "../images/chatbot/user-icon.svg";
import accountIcon from "../images/chatbot/account-icon.svg";
import bedIcon from "../images/chatbot/bed-icon.svg";
import userIconWhite from "../images/chatbot/user-icon-white.svg";
import accountIconWhite from "../images/chatbot/account-icon-white.svg";
import bedIconWhite from "../images/chatbot/bed-icon-white.svg";

function ChatbotContextIcon({ updateChatContext, context }) {

    const getImg = () => {
        if (context[1] === "Resumo do Paciente") {
            return userIcon;
        } else if (context[1] === "Gestão de Leitos") {
            return bedIcon;
        } else {
            return accountIcon;
        }
    }

    const getImgWhite = () => {
        if (context[1] === "Resumo do Paciente") {
            return userIconWhite;
        } else if (context[1] === "Gestão de Leitos") {
            return bedIconWhite;
        } else {
            return accountIconWhite;

        }
    }
    return (
        <div onClick={() => updateChatContext(context)} className="card context-icon-conteiner">
            <div className="context-icon-img-container">
                <img src={getImg()} alt="ícone do contexto" className="chatbot-icon dark-img" />
                <img src={getImgWhite()} alt="ícone do contexto" className="chatbot-icon white-img" />
            </div>
            <div>
                <div className="context-icon-title">{context[1]}</div>
                <div className="context-icon-subtitle">{context[3]}</div>
            </div>
        </div>
    );
}
export default ChatbotContextIcon;