import React, {createContext, useState, useContext, useEffect} from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [userToken, setUserToken] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(true);
    const ENDPOINT = process.env.REACT_APP_API_AUTH_ENDPOINT;

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            setUserToken(token);
        }

        const role = Cookies.get('role');
        if (role) {
            setUserRole(role);
        }
        const storedName = localStorage.getItem("name");
        if (storedName) setName(decodeURIComponent(storedName));

        const storedEmail = localStorage.getItem("email");
        if (storedEmail) setEmail(decodeURIComponent(storedEmail));

        setLoading(false);
    }, []);


    const getUserToken = async (login, password) => {
        try {
            const response = await axios.post(ENDPOINT, {
                login: login,
                password: password
            });

            return response.data;

        } catch (error) {
            console.error('Error getting JWT token:', error);
            throw error;

        }
    };

    const login = async (login, password) => {
        try {
            const response = await getUserToken(login, password);
            if (response) {
                Cookies.set('token', response.token, {expires: 1});
                Cookies.set('role', response.role, {expires: 1});

                localStorage.setItem('name', encodeURIComponent(response.name));
                localStorage.setItem('email', encodeURIComponent(response.email));

                setUserToken(response.token);
                setUserRole(response.role);
                setName(response.name);
                setEmail(response.email);

                return true;

            } else {
                alert('Credenciais inválidas!');
                return false;

            }
        } catch (error) {
            console.error('Credenciais inválidas!', error);
            return false;

        }
    };

    const logout = () => {
        setUserToken(null);
        setUserRole(null);
        setName(null);
        setEmail(null)
        Cookies.remove('token');
        Cookies.remove('role');
        localStorage.removeItem('name');
        localStorage.removeItem('email');

    };

    return (
        <AuthContext.Provider value={{userToken, userRole, name, email, loading, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
