import React, { useState, useEffect, useCallback } from 'react';
import UserListItem from './UsersListItem';

import UserService from '../../services/userService';
import { useAuth } from "../../contexts/AuthProvider";

import './UsersList.css';
import {useNavigate} from "react-router-dom";
import Pagination from "../utils/Paginations"
import UserDropdown from "../users/UserDropdown";

function UsersList() {
    const [usersList, setUsersList] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const { userToken } = useAuth();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);

    const handleNavigation = (path) => () => {
        navigate(path);
    };
    const updateTablePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const getUsersList = useCallback(async () => {
        try {
            setLoadingUsers(true);
            const response = await UserService.getUsersPaginated(userToken, currentPage, pageSize);
            setUsersList(response.users);
            setTotalUsers(response.count);
        } catch (error) {
            console.error("Error fetching users list:", error);
        } finally {
            setLoadingUsers(false);
        }
    }, [userToken, currentPage, pageSize]);


    useEffect(() => {
        getUsersList();
    }, [getUsersList]);

    if (loadingUsers) {
        return null;
    }

    return (
        <div className='user-table-content'>
            <div className='user-list-content'>
                <p className='user-table-title'>Gestão de Usuários</p>                
                <UserDropdown></UserDropdown>
            </div>
            <div className='user-btn'>
                <button className='btn btn-primary' onClick={handleNavigation("/user/form")}>Adicionar usuário</button>
            </div>
            <table>
                <thead className='user-table-head table-header'>
                <tr>
                    <th className='col-3'>Nome</th>
                    <th className='col-3'>E-mail</th>
                    <th className='col-3'>CPF</th>
                    <th className='col-2'>Cargo</th>
                    <th className='col-1'>Ações</th>
                </tr>
                </thead>
                <tbody className='user-table-body'>
                {usersList.map((user, index) =>
                    <UserListItem key={index} user={user} onUserRemoved={getUsersList}/>
                )}
                </tbody>

            </table>
            <Pagination
                cPage={currentPage}
                pageSize={pageSize}
                countOrders={totalUsers}
                updateTablePage={updateTablePage}
            />
        </div>
    );
}

export default UsersList;
