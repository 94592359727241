import React from "react";
import "./Pagination.css";

const Pagination = ({ cPage, pageSize, countOrders, updateTablePage }) => {
    const totalPages = Math.ceil(countOrders / pageSize);

    return (
        <div className="card-pagination">
            <div className="pagination-info">
                Exibindo de {cPage * pageSize + 1} a {Math.min((cPage + 1) * pageSize, countOrders)} de {countOrders}
            </div>
            <div className="pagination-container">
                <ul className="pagination">
                    <li className={`page-item ${cPage === 0 ? "disabled" : ""}`}>
                        <button className="page-link" onClick={() => updateTablePage(cPage - 1)} disabled={cPage === 0}>
                            &laquo;
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <li key={i} className="page-item">
                            <button
                                className={`page-link ${cPage === i ? "active" : ""}`}
                                onClick={() => updateTablePage(i)}
                            >
                                {i + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${cPage + 1 === totalPages ? "disabled" : ""}`}>
                        <button className="page-link" onClick={() => updateTablePage(cPage + 1)} disabled={cPage + 1 === totalPages}>
                            &raquo;
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pagination;
