import axios from 'axios';

const USER_LIST_ENDPOINT = process.env.REACT_APP_API_USER_LIST_ENDPOINT;
const USER_SAVE_ENDPOINT = process.env.REACT_APP_API_USER_SAVE_ENDPOINT;
const USER_VALIDATE_ENDPOINT = process.env.REACT_APP_API_USER_VALIDATE_ENDPOINT;
const USER_DETAILS_ENDPOINT = process.env.REACT_APP_API_USER_BASE_URL_ENDPOINT;
const USER_INACTIVE_ENDPOINT = process.env.REACT_APP_API_USER_INACTIVE_ENDPOINT;
const USER_SEARCH_ENDPOINT = process.env.REACT_APP_API_USER_SEARCH_ENDPOINT;
const USER_PROFILE_ENDPOINT = process.env.REACT_APP_API_USER_PROFILE_ENDPOINT;

const UserService = {
    getAllUsers: async (token) => {
        try {
            const response = await axios.get(USER_LIST_ENDPOINT, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data.object;
        } catch (error) {
            console.error('Error in API call:', error);
            throw error;
        }
    },

    getUsersPaginated: async (token, currentPage, pageSize, searchForm = {}) => {
        try {
            const response = await axios.post(USER_SEARCH_ENDPOINT, searchForm, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                params: {
                    actualPage: currentPage,
                    pageSize: pageSize,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error in API call:', error);
            throw error;
        }
    },

    createUser: async (userData, token) => {
        try {
            const response = await axios.post(USER_SAVE_ENDPOINT, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (error) {
            if (error.response) {
                console.error('API Error:', error.response.data);
            } else if (error.request) {
                console.error('No response from API:', error.request);
            } else {
                console.error('Error creating user:', error.message);
            }
            throw error;
        }
    },

    validateUser: async (id, cpf, email, token) => {
        try {
            const response = await axios.get(USER_VALIDATE_ENDPOINT, {
                params: {id, cpf, email},
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return {success: true, data: response.data};
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return {success: false, errors: error.response.data};
            } else {
                console.error("Erro inesperado na validação:", error);
                return {success: false, errors: {global: "Erro inesperado. Tente novamente."}};
            }
        }
    },

    getUserById: async (userId, token) => {
        try {
            const response = await axios.get(`${USER_DETAILS_ENDPOINT}/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar usuário por ID:', error);
            throw error;
        }
    },

    inactivateUser: async (id, token) => {
        try {
            const response = await axios.put(
                `${USER_INACTIVE_ENDPOINT}/${id}`,
                {}, // <-- Precisa enviar um body vazio
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error("Erro ao inativar usuário:", error);
            throw error;
        }
    },

    getUserByEmail: async (token) => {
        try {
            const storedEmail = localStorage.getItem("email");

            if (!storedEmail) {
                throw new Error("Email não encontrado no localStorage");
            }

            const decodedEmail = decodeURIComponent(storedEmail);

            const response = await axios.get(`${USER_PROFILE_ENDPOINT}/${decodedEmail}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data;
        } catch (error) {
            console.error('Erro ao buscar usuário por email:', error);
            throw error;
        }
    }

};

export default UserService;
