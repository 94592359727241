/* eslint-disable */
import React, { useRef, useEffect } from "react";
import Message from "../components/Message";
import DataTable from "../components/DataTable";
import TypingIndicator from "../components/animation/TypingIndicator";

function ChatbotMessages({
  messages,
  chatId,
  chatInfo,
  newMessageInChatHistory,
  finishedStream,
  loadingAnswer,
  selectedContextValue,
  getDataTable,
  hasError,
  isPatientNotFound,
  getContextMessage,
  scrollToBottom,
  showFeedback = true,
}) {
  const chatWindowRef = useRef(null);
  useEffect(() => {
    scrollToBottom(chatWindowRef);
  }, [messages, scrollToBottom]);

  const showTableContent = () => {
    return (
      !loadingAnswer &&
      selectedContextValue === "taxa_ocupacao_leitos" &&
      !hasError(chatInfo)
    );
  };

  const resumoPacienteChatFinished = () => {
    return (
      !loadingAnswer &&
      selectedContextValue === "resumo_paciente" &&
      messages.length > 1 &&
      !hasError(chatInfo) &&
      !isPatientNotFound(chatInfo)
    );
  };

  const getPatientDashboardMessage = () => {
    const patientId = messages[messages.length - 2]?.content;
    const dashboardPath = `/dashboard/${patientId}`;
    return `Clique no link abaixo para acessar o dashboard do paciente: \n [Dashboard do Paciente](${dashboardPath})`;
  };

  return (
    <div className="chatbot-window chatbot-chat" ref={chatWindowRef}>
      <Message
        content={getContextMessage()}
        role={"assistant"}
        stream={messages.length === 0}
        scrollToBottom={scrollToBottom}
        currentRef={chatWindowRef}
        showFeedback={showFeedback}
      />

      {messages.map((message, index) => {
        if (message.content !== undefined) {
          const stream =
            index === messages.length - 1 &&
            (chatId === null || newMessageInChatHistory);
          return (
            <Message
              key={index}
              content={message.content}
              role={message.role}
              stream={stream}
              scrollToBottom={scrollToBottom}
              currentRef={chatWindowRef}
              messageId={message.id}
              displayFeedbackOptions={showFeedback}
              messageFeedback={message.feedback}
              showFeedback={showFeedback}
            />
          );
        }
        return null;
      })}

      { finishedStream && resumoPacienteChatFinished() && (
        <Message
          content={getPatientDashboardMessage()}
          role="assistant"
          stream={false}
          scrollToBottom={scrollToBottom}
          currentRef={chatWindowRef}
          showFeedback={showFeedback}
        />
      ) }

      {finishedStream && showTableContent() && (
        <DataTable data={getDataTable(chatInfo)} />
      )}
      
      {finishedStream && loadingAnswer && <TypingIndicator />}
    </div>
  );
}

export default ChatbotMessages;
