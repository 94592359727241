import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const getDashboardData = async (token,
      tabName,
      doencaFilter = null,
      medicamentoFilter = null,
      procedimentoFilter = null,
      startDateFilter = null,
      endDateFilter = null) => {
  const ENDPOINT = process.env.REACT_APP_API_DASHBOARD_ENDPOINT;

  try {
    const response = await axios.post(ENDPOINT,
      {
        tabName: tabName,
        doencaFilter: doencaFilter,
        medicamentoFilter: medicamentoFilter,
        procedimentoFilter: procedimentoFilter,
        startDateFilter: startDateFilter,
        endDateFilter: endDateFilter,
        patientId: null
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.object;
  } catch (error) {
    console.error('Error getting dashboard data:', error);
    throw error;
  }
};

const getPatientDashboardData = async (token,
  patientId,
  tabName,
  doencaFilter = null,
  medicamentoFilter = null,
  procedimentoFilter = null,
  startDateFilter = null,
  endDateFilter = null) => {
  const ENDPOINT = process.env.REACT_APP_API_PATIENT_DASHBOARD_ENDPOINT;

  try {
    const response = await axios.post(ENDPOINT,
      {
        tabName: tabName,
        doencaFilter: doencaFilter,
        medicamentoFilter: medicamentoFilter,
        procedimentoFilter: procedimentoFilter,
        startDateFilter: startDateFilter,
        endDateFilter: endDateFilter,
        patientId: patientId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.object;
  } catch (error) {
    console.error('Error getting dashboard data:', error);
    throw error;

  }
};

const getMostCommonItemData = (dashboardData) => {
  if (!dashboardData || !dashboardData.mostCommonItems) return null;

  const item = Object.keys(dashboardData.mostCommonItems);
  const recurrence = Object.values(dashboardData.mostCommonItems);

  const barChartOptions = {
    data: {
      labels: item,
      datasets: [
        {
          label: 'Registros',
          data: recurrence,
          backgroundColor: '#0A91FF',
          borderRadius: 8,
          borderSkipped: false,
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          display: false
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false
          },
          ticks: {
            maxRotation: 45,
            minRotation: 45,
          }
        }
      }
    }
  };

  return barChartOptions;
};

const getRegisterPerMonthData = (dashboardData) => {
  if (!dashboardData || !dashboardData.registerPerMonth) return null;

  const labels = Object.keys(dashboardData.registerPerMonth);
  const values = Object.values(dashboardData.registerPerMonth);

  const createGradient = (ctx, chartArea, transp) => {
    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(0.16, `rgba(252, 84, 82, ${transp})`);
    gradient.addColorStop(1, `rgba(86, 84, 212, ${transp})`);
    return gradient;
  };

  const lineChartOptions = {
    data: {
      labels: labels,
      datasets: [
        {
          label: 'Registros',
          data: values,
          tension: 0,
          pointRadius: 0,
          fill: true,
          borderColor: (ctx) => {
            const chart = ctx.chart;
            const { ctx: canvasCtx, chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            return createGradient(canvasCtx, chartArea, 0.8);
          },
          backgroundColor: (ctx) => {
            const chart = ctx.chart;
            const { ctx: canvasCtx, chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            return createGradient(canvasCtx, chartArea, 0.3);
          },
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          display: false,
          position: 'top',
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 45,
            minRotation: 45,
          },
        },
        y: {
          title: {
            display: false,
          },
          grid: {
            display: true,
          },
        },
      },
    },
  };

  return lineChartOptions;
};

const getRegisterPerGenderData = (dashboardData) => {
  if (!dashboardData || !dashboardData.registerPerGender) return null;

  const labels = Object.keys(dashboardData.registerPerGender);
  const data = Object.values(dashboardData.registerPerGender);

  if (data.length === 0) return null;

  const total = data.reduce((sum, value) => sum + value, 0);

  const pieChartOptions = {
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#9B55CA", "#0A91FF", "#83DBC9"],
          hoverBackgroundColor: ["#9B55CAAA", "#0A91FFAA", "#83DBC9AA"],
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        datalabels: {
          formatter: (value) => {
            const percentage = ((value / total) * 100).toFixed(2);
            return percentage > 15 ? `${percentage}%` : '';
          },
          color: "#fff",
          font: {
            weight: "bold",
            size: 14,
          },
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const value = tooltipItem.raw;
              return `${tooltipItem.label}: ${value} pacientes`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
    },
    plugins: [ChartDataLabels],
  };

  return pieChartOptions;
};

const isDataEmpty = (dashboardData, graph, dataAtribute) => {
  if (dashboardData === null || dashboardData[graph] === undefined) return false;

  const attributeValue = dashboardData[graph][dataAtribute];
  if (attributeValue && typeof attributeValue === 'object' && Object.keys(attributeValue).length === 0) {
    return true;
  }

  return false;
};

export {
  getDashboardData,
  getMostCommonItemData,
  getRegisterPerMonthData,
  getRegisterPerGenderData,
  getPatientDashboardData,
  isDataEmpty
};
