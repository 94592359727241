import {useAuth} from "../../contexts/AuthProvider";
import {useState} from "react";
import {FaChevronDown, FaChevronUp, FaUser} from "react-icons/fa";
import {FaArrowRightFromBracket} from "react-icons/fa6";

const UserDropdown = ({isInsideContextBox = false}) => {
    const {name, email, logout} = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={`user-dropdown relative ${isInsideContextBox ? "inside-context-box" : ""}`}
        >
            <div
                className="user-info flex items-center gap-2 p-2 cursor-pointer bg-gray-100 rounded-lg"
                onClick={() => setIsOpen((prevState) => !prevState)}
            >
                <div
                    className="user-avatar-circle bg-blue-500 text-white flex items-center justify-center rounded-full w-8 h-8 text-lg">
                    {name ? name.charAt(0) : "N"}
                </div>
                {!isInsideContextBox && (
                    <>
                        <div className="user-details">
                            <span className="user-name font-semibold truncate">{name || "Nome do Usuário"}</span>
                            <span
                                className="user-email text-sm text-gray-600 block truncate">{email || "email@email.com"}</span>
                        </div>
                        <button className="arrow">
                            {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                    </>
                )}
            </div>

            {isOpen && (
                <div className="dropdown-menu-user">
                    <div className="dropdown-options">
                        <div className="px-2 py-2">
                            <a href="/user/profile" className="flex items-center">
                                <FaUser className="mr-2"/> Perfil
                            </a>
                        </div>
                        <div>
                            <button onClick={logout} className="flex items-center px-2 py-2">
                                <FaArrowRightFromBracket className="mr-2"/> Sair
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserDropdown;
