import React from "react";
import ReactDOM from "react-dom/client";
import Chatbot from "./webComponents/Chatbot";
import { AuthProvider } from "./contexts/AuthProvider";
import { MessagesProvider } from "./contexts/MessageProvider";

// Importar estilos como string usando raw-loader
import appStyles from "./App.css";
import indexStyles from "./index.css";
import chatbotStyles from "./webComponents/Chatbot.css";
import inputBoxStyles from "./webComponents/ChatbotInputBox.css";
import messageStyles from "./components/Message.css";
import chatIconStyles from "./components/ChatIcon.css";
import dataTabletyles from "./components/DataTable.css";
import chatContextIconStyles from "./webComponents/ChatbotContextIcon.css";

class ChatbotWebComponent extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });

    // Criar um elemento <style> e injetar os estilos
    const style = document.createElement("style");
    style.textContent = `  ${appStyles}  ${indexStyles} ${chatbotStyles} 
    ${inputBoxStyles} ${messageStyles} ${chatIconStyles}  ${dataTabletyles} ${chatContextIconStyles}`;

    // Criar um container para o React dentro do Shadow DOM
    this.root = document.createElement("div");

    // Adicionar os estilos e o container ao Shadow DOM
    this.shadowRoot.appendChild(style);
    this.shadowRoot.appendChild(this.root);
  }

  connectedCallback() {
    if (!this.reactRoot) {
      this.reactRoot = ReactDOM.createRoot(this.root);
      this.reactRoot.render(
        <AuthProvider>
          <MessagesProvider>
            <Chatbot />
          </MessagesProvider>
        </AuthProvider>
      );
    }
  }

  disconnectedCallback() {
    if (this.reactRoot) {
      this.reactRoot.unmount();
    }
  }
}

customElements.define("chatbot-webcomponent", ChatbotWebComponent);
