import React, {useEffect, useMemo, useState} from 'react';
import {TextField, Typography, MenuItem, Select, FormControl} from '@mui/material';
import './UserForm.css';
import UserService from '../../services/userService';
import {useAuth} from "../../contexts/AuthProvider";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CustomConfirmationModal from "../modal/CustomConfirmationModal";
import {useLocation, useNavigate} from "react-router-dom";
import {formatCpf, formatPhone, isValidCPF} from "../utils/MaskUtils"
import ErrorIcon from '@mui/icons-material/Error';
import UserDropdown from "../users/UserDropdown";

function UserForm({mode, userId, onUserRemoved}) {
    const {userToken} = useAuth();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [birthDate, setBirthdate] = useState('');
    const [role, setRole] = useState('');
    const [phone, setPhone] = useState('');
    let [idProfile, setIdProfile] = useState('');


    const [errorName, setErrorName] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorCpf, setErrorCpf] = useState('');
    const [errorBirthdate, setErrorBirthdate] = useState('');
    const [errorRole, setErrorRole] = useState('');
    const [errorPhone] = useState('');

    const today = new Date().toISOString().split('T')[0];
    const roles = useMemo(() => [
        { code: 'ROLE_ADMIN', label: 'Administrador' },
        { code: 'ROLE_MANAGER', label: 'Gerente' },
        { code: 'ROLE_USER', label: 'Usuário' }
    ], []);

    const isViewMode = mode === 'view';
    const isEditMode = mode === 'edit';
    const isProfileMode = mode === 'profile';
    const isEditProfileMode = mode === 'editProfile';
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalSubtitle, setModalSubtitle] = useState('');
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [progressModalOpen, setProgressModalOpen] = useState(false);

    const originalFormData = useMemo(() => ({
        name: '',
        email: '',
        cpf: '',
        birthDate: '',
        role: '',
        phone: ''
    }), []);

    useEffect(() => {
        if (isProfileMode || isEditProfileMode) {
            const fetchUserDetails = async () => {
                try {
                    const response = await UserService.getUserByEmail(userToken);
                    const userDetails = response.object;

                    setIdProfile(userDetails.id);
                    setName(userDetails.person.name);
                    setEmail(userDetails.email);
                    setCpf(userDetails.person.cpf);
                    setBirthdate(userDetails.person.birthDate.split('T')[0]);

                    const roleFound = roles.find(r => r.label === userDetails.role);
                    setRole(roleFound ? roleFound.code : '');

                    setPhone(userDetails.person.phone || '');
                } catch (error) {
                    console.error('Erro ao buscar detalhes do usuário:', error);
                }
            };

            fetchUserDetails();
        } else if ((isViewMode || isEditMode) && userId) {
            const fetchUserDetails = async () => {
                try {
                    const response = await UserService.getUserById(userId, userToken);
                    const userDetails = response.object;

                    setName(userDetails.person.name);
                    setEmail(userDetails.email);
                    setCpf(userDetails.person.cpf);
                    setBirthdate(userDetails.person.birthDate.split('T')[0]);

                    const roleFound = roles.find(r => r.label === userDetails.role);
                    setRole(roleFound ? roleFound.code : '');

                    setPhone(userDetails.person.phone || '');
                } catch (error) {
                    console.error('Erro ao buscar detalhes do usuário:', error);
                }
            };

            fetchUserDetails();
        }
    }, [isProfileMode, isEditProfileMode, isViewMode, isEditMode, userId, userToken, roles]);


    useEffect(() => {
        const formData = {
            name,
            email,
            cpf,
            birthDate,
            role,
            phone
        };
        const isDirty = Object.keys(formData).some(key => formData[key] !== originalFormData[key]);
        setIsFormDirty(isDirty);
    }, [name, email, cpf, birthDate, role, phone, originalFormData]);



    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const formElements = Array.from(e.target.form.elements).filter(
                (el) => el.tagName === 'INPUT' || el.tagName === 'TEXTAREA'
            );
            const index = formElements.indexOf(e.target);
            if (formElements[index + 1]) {
                formElements[index + 1].focus();
            }
        }
    };

    const handleBlur = (field, value, setError) => {
        if (!value.trim()) {
            setError('Campo obrigatório');
        } else {
            setError('');
        }
    };

    const handleCpfBlur = (e) => {
        const cpfValue = e.target.value;
        setCpf(cpfValue);

        if (!cpfValue.trim()) {
            setErrorCpf('Campo obrigatório');
        } else if (!isValidCPF(cpfValue)) {
            setErrorCpf('CPF inválido');
        } else {
            setErrorCpf('');
        }
    };

    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailBlur = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        if (!emailValue.trim()) {
            setErrorEmail('Campo obrigatório');
        } else if (!isValidEmail(emailValue)) {
            setErrorEmail('E-mail inválido');
        } else {
            setErrorEmail('');
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        let hasError = false;

        setErrorName('');
        setErrorCpf('');
        setErrorEmail('');
        setErrorRole('');
        setErrorBirthdate('');

        if (!name.trim()) {
            setErrorName('Campo obrigatório');
            hasError = true;
        }

        if (!cpf.trim()) {
            setErrorCpf('Campo obrigatório');
            hasError = true;
        } else if (!isValidCPF(cpf)) {
            setErrorCpf('CPF inválido');
            hasError = true;
        }

        if (!email.trim()) {
            setErrorEmail('Campo obrigatório');
            hasError = true;
        } else if (!isValidEmail(email)) {
            setErrorEmail('E-mail inválido');
            hasError = true;
        }

        if (!role.trim()) {
            setErrorRole('Campo obrigatório');
            hasError = true;
        }

        if (hasError || errorCpf || errorEmail || errorName || errorRole || errorBirthdate) {
            return;
        }

        const cleanedCpf = cpf.replace(/[^\d]+/g, '');

        if(isEditProfileMode || isProfileMode){
            userId = idProfile
        }
        const validationResult = await UserService.validateUser(userId, cleanedCpf, email, userToken);

        if (!validationResult.success) {
            const {errors} = validationResult;

            if (errors.cpf) setErrorCpf(errors.cpf);
            if (errors.email) setErrorEmail(errors.email);

            return;
        }

        const userData = {
            id: userId,
            email,
            role,
            person: {
                name,
                cpf: cleanedCpf,
                birthDate,
                phone
            }
        };

        try {
            await UserService.createUser(userData, userToken);
            setModalTitle(isEditMode || isEditProfileMode ? 'Usuário editado!' : 'Usuário cadastrado!');
            setModalSubtitle('A operação foi concluída com sucesso.');
            setShowSuccessModal(true);
        } catch (error) {
            console.error('❌ Erro ao cadastrar usuário:', error);
            alert('Erro ao cadastrar usuário. Verifique os campos e tente novamente.');
        }
    };

    const handleBirthdateChange = (e) => {
        const selectedDate = e.target.value;
        setBirthdate(selectedDate);

        if (selectedDate > today) {
            setErrorBirthdate('Data não pode ser posterior a hoje.');
        } else {
            setErrorBirthdate('');
        }
    };
    const handleCpfChange = (e) => {
        let cpfValue = e.target.value.replace(/\D/g, '');

        if (cpfValue.length > 11) {
            cpfValue = cpfValue.slice(0, 11);
        }

        setCpf(formatCpf(cpfValue));
    };

    const handlePhoneChange = (e) => {
        setPhone(formatPhone(e.target.value));
    };

    const location = useLocation();

    const handleGoBack = () => {
        const currentPath = location.pathname;

        const isEditingProfile = currentPath === "/user/edit/profile";

        if (isFormDirty && !isProfileMode && !isViewMode) {
            setModalTitle(
                isEditingProfile
                    ? "Tem certeza que deseja voltar para a tela de meu perfil?"
                    : "Tem certeza que deseja voltar para a tela de listagem?"
            );
            setModalSubtitle("As alterações feitas no seu perfil serão perdidas.");
            setShowConfirmModal(true);
        } else {
            window.history.back();
        }
    };

    const handleConfirmRemove = async () => {
        try {
            await UserService.inactivateUser(userId, userToken);
            setModalOpen(false);
            setProgressModalOpen(true);
        } catch (error) {
            console.error('Erro ao inativar usuário:', error);
            alert("Erro ao inativar usuário.");
        }
    };

    const handleRemoveUserClick = () => {
        setModalOpen(true);
    };

    const handleProgressModalClose = () => {
        console.log("entrou para retornar para a listagem")
        setProgressModalOpen(false);

        setTimeout(() => {
            if (onUserRemoved) {
                onUserRemoved(userId);
            }
            navigate("/user/list");
        }, 300);
    };


    return (
        <div className="register-container">
            <div className="register-title-container">
                {mode === "view" && (
                    <p className="user-table-title">Visualização de Usuário</p>)}

                {(mode === "edit" || mode === "editProfile") && (
                    <p className="user-table-title">Edição de Usuário</p>
                )}

                {mode === "create" && (
                    <p className="user-table-title">Criação de Usuário</p>)}

                {mode === "profile" && (
                    <p className="user-table-title">Meu perfil</p>)}
                <UserDropdown></UserDropdown>
            </div>
            <div className="col-12 d-flex mb-3">
                {/* Edit Button */}
                {(isViewMode || isProfileMode) && (
                    <a
                        className="user-action-btn me-2 text-decoration-none"
                        href={isViewMode ? `/user/edit/${userId}` : `/user/edit/profile`}
                    >
                        Editar
                    </a>
                )}

                {/* Remove Button */}
                {(isEditMode || isViewMode) && (
                    <button
                        type="button"
                        className="user-action-btn"
                        onClick={handleRemoveUserClick}
                    >
                        Remover
                    </button>
                )}

            </div>


            <form onSubmit={handleSubmit} className="form">
                {/* Nome */}
                <div className="form-group mb-3 col-12">
                    <label htmlFor="name">Nome completo <span style={{color: 'red'}}>*</span></label>
                    <TextField
                        fullWidth
                        id="name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={() => handleBlur('name', name, setErrorName)}
                        placeholder="Nome completo"
                        className="form-control"
                        error={Boolean(errorName)}
                        onKeyDown={handleKeyDown}
                        disabled={isViewMode || isProfileMode}
                        sx={{backgroundColor: isViewMode ? '#f5f5f5' : 'white'}}
                    />
                    {errorName && <Typography className="error-message">{errorName}</Typography>}

                </div>

                <div className="row">
                    <div className="form-group mb-3 col-12 col-md-6">
                        <label htmlFor="cpf">CPF <span style={{color: 'red'}}>*</span></label>
                        <TextField
                            fullWidth
                            id="cpf"
                            variant="outlined"
                            value={isProfileMode || isViewMode || isEditMode ? formatCpf(cpf) : cpf}
                            onChange={handleCpfChange}
                            onBlur={handleCpfBlur}
                            placeholder="000.000.000-00"
                            className="form-control"
                            error={Boolean(errorCpf)}
                            onKeyDown={handleKeyDown}
                            disabled={isViewMode || isEditMode || isProfileMode || isEditProfileMode}
                            sx={{backgroundColor: isViewMode || isEditMode ? '#f5f5f5' : 'white'}}
                        />

                        {errorCpf && <Typography className="error-message">{errorCpf}</Typography>}
                    </div>

                    {/* Data de Nascimento */}
                    <div className="form-group mb-3 col-12 col-md-6">
                        <label htmlFor="birthDate">Data de nascimento</label>
                        <TextField
                            fullWidth
                            id="birthDate"
                            variant="outlined"
                            value={birthDate}
                            type="date"
                            onChange={handleBirthdateChange}
                            placeholder="00/00/0000"
                            className="form-control"
                            error={Boolean(errorBirthdate)}
                            inputProps={{max: today}}
                            onKeyDown={handleKeyDown}
                            disabled={isViewMode || isProfileMode}
                            sx={{backgroundColor: isViewMode ? '#f5f5f5' : 'white'}}
                        />
                        {errorBirthdate && <Typography className="error-message">{errorBirthdate}</Typography>}
                    </div>
                </div>

                <div className="row">
                    {/* Email */}
                    <div className="form-group mb-3 col-12 col-md-6">
                        <label htmlFor="email">Email <span style={{color: 'red'}}>*</span></label>
                        <TextField
                            fullWidth
                            id="email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={handleEmailBlur}
                            placeholder="Email"
                            className="form-control"
                            error={Boolean(errorEmail)}
                            onKeyDown={handleKeyDown}
                            disabled={isViewMode || isProfileMode}
                            sx={{backgroundColor: isViewMode ? '#f5f5f5' : 'white'}}
                        />
                        {errorEmail && <Typography className="error-message">{errorEmail}</Typography>}
                    </div>

                    {/* Telefone */}
                    <div className="form-group mb-3 col-12 col-md-6">
                        <label htmlFor="phone">Telefone</label>
                        <TextField
                            fullWidth
                            id="phone"
                            variant="outlined"
                            value={phone}
                            onChange={handlePhoneChange}
                            placeholder={isViewMode || isProfileMode ? '' : '(99) 99999-9999'}
                            className="form-control"
                            error={Boolean(errorPhone)}
                            onKeyDown={handleKeyDown}
                            disabled={isViewMode || isProfileMode}
                            sx={{backgroundColor: isViewMode ? '#f5f5f5' : 'white'}}
                        />
                    </div>
                </div>

                {/* Cargo (Select) */}
                <div className="form-group mb-3">
                    <FormControl fullWidth error={Boolean(errorRole)}>
                        <label htmlFor="role">Cargo <span style={{color: 'red'}}>*</span></label>
                        <Select
                            labelId="role-label"
                            id="role"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            onBlur={() => handleBlur('role', role, setErrorRole)}
                            displayEmpty
                            sx={{backgroundColor: isViewMode || (isProfileMode && ["MANAGER", "USER"].includes(role)) ? '#f5f5f5' : 'white'}}
                            disabled={isViewMode || isProfileMode || (isEditProfileMode && role !== "ROLE_ADMIN")}
                        >
                            <MenuItem value="" disabled>
                                Selecione
                            </MenuItem>
                            {roles.map((r) => (
                                <MenuItem key={r.code} value={r.code}>
                                    {r.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {errorRole && <Typography className="error-message">{errorRole}</Typography>}
                    </FormControl>
                </div>

                {(!isEditMode && !isViewMode && !isProfileMode && !isEditProfileMode) && (
                    <div className="alert-container alert-custom mb-3">
                        <ErrorIcon className="ms-3 me-3" style={{color: '#5B85D2', fontSize: 16}}/>
                        <span className="alert-text">A primeira senha será o CPF apenas com números.</span>
                    </div>
                )}

                <div className="form-group mb-3 d-flex justify-content-between">
                    <button type="button" className="btn-back" onClick={handleGoBack}>
                        <ArrowBackIosIcon/> Voltar
                    </button>

                    {!(isViewMode || isProfileMode) && (
                        <button
                            type="submit"
                            className="btn-cadastrar"
                        >
                            {isEditMode || isEditProfileMode ? 'Editar' : 'Cadastrar'}
                        </button>
                    )}
                </div>


            </form>
            <CustomConfirmationModal
                open={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={() => {
                    setShowSuccessModal(false);
                    const isEditingProfile = location.pathname === "/user/edit/profile";
                    navigate(isEditingProfile ? "/profile" : "/user/list");
                }}
                title={modalTitle}
                subtitle={modalSubtitle}
                confirmText="Sim"
                cancelText="Cancelar"
            />

            <CustomConfirmationModal
                open={showSuccessModal}
                onClose={() => setShowSuccessModal(false)}
                onConfirm={() => {
                    setShowSuccessModal(false);
                    const isEditingProfile = location.pathname === "/user/edit/profile";
                    navigate(isEditingProfile ? "/profile" : "/user/list");
                }}
                title={modalTitle}
                subtitle={modalSubtitle}
                showProgress={true}
            />

            <CustomConfirmationModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleConfirmRemove}
                title="Tem certeza que deseja remover este usuário?"
                subtitle="Os dados do usuário serão perdidos."
                confirmText="Sim"
                cancelText="Cancelar"
            />

            <CustomConfirmationModal
                open={progressModalOpen}
                onClose={handleProgressModalClose}
                onConfirm={handleProgressModalClose}
                title="Usuário removido com sucesso!"
                subtitle=""
                showProgress={true}
                confirmText="Fechar"
            />

        </div>
    );
}

export default UserForm;
