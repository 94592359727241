/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useMessages } from "../contexts/MessageProvider";
import { useAuth } from "../contexts/AuthProvider";
import ChatApi from "../services/chatAPIService";
import ChatbotInputBox from "./ChatbotInputBox";
import ChatMessages from "./ChatbotMessages";
import ChatbotContextIcon from "./ChatbotContextIcon";

import "./Chatbot.css";
import haisLogo from "../images/logo.svg";

function Chatbot() {

  const RESUMO_PACIENTE_CONTEXT = [
    "resumo_paciente",
    "Resumo do Paciente",
    "Você selecionou **Resumo do Paciente**. Envie o ID do paciente para darmos início à conversa.",
    "Questões relacionadas a pacientes"
  ];
  const GESTAO_LEITOS_CONTEXT = [
    "gestao_leitos",
    "Gestão de Leitos",
    "Você selecionou **Gestão de Leitos**. Envie as informações do paciente para darmos início à conversa.",
    "Visão geral dos status dos leitos"
  ];
  const TAXA_OCUPACAO_CONTEXT = [
    "taxa_ocupacao_leitos",
    "Taxa de Ocupação de Leitos",
    "Você selecionou **Taxa de Ocupação de Leitos**.",
    "Visão geral dos leitos"
  ];
  const DUVIDAS_GERAIS = [
    "duvidas_gerais",
    "Dúvidas Gerais",
    "Você selecionou **Dúvidas Gerais**. Envie seu questionamento para darmos início à conversa.",
    "Questões amplas"
  ];

  const { login, userToken } = useAuth();
  const [newChatId, setNewChatId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatInfo, setChatInfo] = useState(null);
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [selectedContext, setSelectedContext] = useState("");

  const chatWindowRef = useRef(null);
  const chatbotLogin = process.env.REACT_APP_API_CHATBOT_lOGIN;
  const chatbotPassword = process.env.REACT_APP_API_CHATBOT_PASSWORD;

  useEffect(() => {
    const performLogin = async () => {
      try {
        await login(chatbotLogin, chatbotPassword);
        setIsLoggedIn(true);
      } catch (error) {
        console.error("Login failed", error);
      }
    };
    performLogin();
  }, [login]);

  const { messages,
    addMessage,
    updateMessages,
    finishedStream,
    lastStreamMessage,
    isStreaming,
    clearMessages
  } = useMessages();

  const { sendPrompt,
    getDataTable,
    hasError,
    isPatientNotFound
  } = ChatApi(userToken);

  const handleChatSubmit = async (message) => {

    if (loadingAnswer) return;

    setLoadingAnswer(true);

    if (!isLoggedIn) return;
    let contextValue = selectedContext[0];
    let prompt = `${contextValue}#${message}`;
    addMessage(message, "user");

    try {
      const chatData = await sendPrompt(prompt, newChatId);
      updateMessages(chatData.chat.messages);
      setNewChatId(chatData.chat.id);
      setChatInfo(chatData.chat);

    } catch (error) {
      console.error("Error while fetching chat response:", error);
    } finally {
      setLoadingAnswer(false);
    }
  };

  const scrollToBottom = (currentRef) => {
    if (currentRef.current) {
      currentRef.current.scrollTo({
        top: currentRef.current.scrollHeight
      });
    }
  };

  useEffect(() => {
    scrollToBottom(chatWindowRef);
  }, [messages, lastStreamMessage]);

  const getContextMessage = () => {
    return selectedContext[2];
  };

  const updateChatContext = (context) => {
    setSelectedContext(context);
  }

  const closeChat = () => {
    clearMessages();
    setSelectedContext("");
    setIsChatOpen(false);
    setNewChatId("");
  }

  const hideChat = () => {
    setIsChatOpen(false);
  }

  const backToMenu = () => {
    clearMessages();
    setSelectedContext("");
    setNewChatId("");
  }

  return (
    <>
      {/* Botão para abrir o chatbot */}
      {!isChatOpen && (
        <button className="chatbot-toggle-button" onClick={() => setIsChatOpen(true)}>
          <img src={haisLogo} className="chatbot-icon" />
        </button>
      )}

      {/* Chatbot */}
      {isChatOpen && (
        <div className="chatbot-container">
          <div className="card chatbot-header">
            <div className="chatbot-title">
              {selectedContext !== "" && <div onClick={backToMenu} style={{ cursor: 'pointer' }}>⭠</div>}
              <div>Assistente Virtual Hospitalar</div>
              <div>
                <button className="close-chatbot" onClick={closeChat}>✖</button>
                <button className="hide-chatbot" onClick={hideChat}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M19 13H5v-2h14v2z" />
                  </svg>
                </button>
              </div>
            </div>
            {selectedContext !== "" ?
              <div className="chatbot-context">{selectedContext[1]}</div>
              :
              <div className="chatbot-context">Selecione o seu assunto</div>
            }
          </div>
          <div className={'chatbot-content ' + (selectedContext === "" ? 'menu' : '')}>
            {
              selectedContext !== "" ?
                <>
                  <ChatMessages
                    messages={messages}
                    chatId={null}
                    chatInfo={chatInfo}
                    newMessageInChatHistory={true}
                    finishedStream={finishedStream}
                    loadingAnswer={loadingAnswer}
                    selectedContextValue={selectedContext[1]}
                    getDataTable={getDataTable}
                    hasError={hasError}
                    isPatientNotFound={isPatientNotFound}
                    getContextMessage={getContextMessage}
                    hideInput={false}
                    scrollToBottom={scrollToBottom}
                    showFeedback={false}
                  />
                  <div className="chatbot-input">
                    {isLoggedIn && (
                      <ChatbotInputBox
                        beginChatbotInputBox={true}
                        loadingAnswer={loadingAnswer || isStreaming}
                        handleChatSubmit={handleChatSubmit}
                      />
                    )}
                  </div>
                </>
                :
                <>
                  <ChatbotContextIcon
                    updateChatContext={updateChatContext}
                    context={RESUMO_PACIENTE_CONTEXT}>
                  </ChatbotContextIcon>
                  <ChatbotContextIcon
                    updateChatContext={updateChatContext}
                    context={GESTAO_LEITOS_CONTEXT}>
                  </ChatbotContextIcon>
                  <ChatbotContextIcon
                    updateChatContext={updateChatContext}
                    context={DUVIDAS_GERAIS}>
                  </ChatbotContextIcon>
                </>
            }
          </div>
        </div>
      )}
    </>
  );
}

export default Chatbot;
