import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
    LinearProgress
} from "@mui/material";
import { AlertCircle } from "lucide-react";

const CustomConfirmationModal = ({
                                     open,
                                     onClose,
                                     onConfirm,
                                     icon = <AlertCircle size={60} color="#f4a261" />,
                                     title = "Tem certeza?",
                                     subtitle = "Esta ação não pode ser desfeita.",
                                     confirmText = "Sim",
                                     cancelText = "Cancelar",
                                     confirmColor = "primary",
                                     cancelColor = "error",
                                     showProgress = false
                                 }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (open && showProgress) {
            setProgress(0);
            const interval = setInterval(() => {
                setProgress((prev) => {
                    if (prev >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return prev + 10;
                });
            }, 300);
            return () => clearInterval(interval);
        }
    }, [open, showProgress]);

    useEffect(() => {
        if (progress === 100 && showProgress) {
            setTimeout(() => {
                onConfirm();
                onClose();
            }, 500);
        }
    }, [progress, onConfirm, onClose, showProgress]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle className="text-center">
                <IconButton disableRipple>
                    {icon}
                </IconButton>
            </DialogTitle>

            <DialogContent className="text-center">
                <Typography variant="h6" gutterBottom>{title}</Typography>
                <Typography variant="body2" color="textSecondary">{subtitle}</Typography>

                {showProgress ? (
                    <div className="mt-4">
                        <LinearProgress variant="determinate" value={progress} />
                    </div>
                ) : null}
            </DialogContent>

            {!showProgress && (
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={onClose} color={cancelColor} variant="contained">
                        {cancelText}
                    </Button>
                    <Button onClick={onConfirm} color={confirmColor} variant="contained">
                        {confirmText}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default CustomConfirmationModal;
