import React from "react";
import "./ContextBox.css";
import UserDropdown from "./users/UserDropdown";

const ContextBox = ({contextTitle}) => {
    if (!contextTitle) return null;

    return (
        <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="context-box">{contextTitle}</div>
            <div><UserDropdown isInsideContextBox={true}/></div>
        </div>
    );
};

export default ContextBox;
