import React from 'react';
import Sidebar from '../Sidebar';
import UsersList from '../users/UsersList';
import '../users/UserDropdown.css';

function UsersRoute() {
    return (
        <div className="app">
            <Sidebar/>
            <div className="main-content">                
                <UsersList></UsersList>
            </div>
        </div>
    );
}

export default UsersRoute;
