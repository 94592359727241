import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar';
import UserForm from '../users/UserForm';

function UserFormRoute({ mode }) {
    const { userId } = useParams();
    const location = useLocation();

    return (
        <div className="app">
            <Sidebar />
            <div className="main-content">
                <UserForm mode={mode} userId={userId} location={location} />
            </div>
        </div>
    );
}

export default UserFormRoute;
