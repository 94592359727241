import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./chatbot-webcomponent";
import "bootstrap/dist/css/bootstrap.min.css";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

if (!customElements.get("chatbot-webcomponent")) {
  import("./chatbot-webcomponent").then(() => {
    console.log("Chatbot WebComponent registrado.");
  });
}

reportWebVitals();
