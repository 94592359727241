import React, { createContext, useContext, useState } from 'react';

const MessagesContext = createContext();

export const MessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [lastStreamMessage, setLastStreamMessage] = useState("");
  const [finishedStream, setFinishedStream] = useState(true);

  function updateMessages(messagesList) {
    setMessages(messagesList);
  }
  
  function addMessage(message, role) {
    updateMessages([...messages, { content: message, role: role }]);
  }  

  function clearMessages(){
    setMessages([]);
  }

  return (
    <MessagesContext.Provider value={{ messages, updateMessages, setMessages, addMessage, 
    isStreaming, setIsStreaming, lastStreamMessage, setLastStreamMessage, finishedStream, setFinishedStream, clearMessages}}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
