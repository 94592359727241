import React, { useState } from 'react';
import './UsersList.css';
import UserService from '../../services/userService';
import { useAuth } from '../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from "@mui/material";
import CustomConfirmationModal from "../modal/CustomConfirmationModal";

function UsersListItem({ user, onUserRemoved }) {
    const { userToken } = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [progressModalOpen, setProgressModalOpen] = useState(false);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = (event) => {
        event.stopPropagation();
        navigate(`/user/edit/${user.id}`);
        handleClose();
    };

    const handleOpenModal = (event) => {
        event.stopPropagation();
        setModalOpen(true);
        handleClose();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleConfirmRemove = async () => {
        try {
            await UserService.inactivateUser(user.id, userToken);
            setModalOpen(false);
            setProgressModalOpen(true);
        } catch (error) {
            console.error('Erro ao inativar usuário:', error);
            alert("Erro ao inativar usuário.");
        }
    };

    const handleUserClick = async (userId) => {
        try {
            const userDetails = await UserService.getUserById(userId, userToken);
            navigate(`/user/view/${userId}`, { state: { userDetails } });
        } catch (error) {
            console.error('Erro ao buscar detalhes do usuário:', error);
        }
    };

    const handleProgressModalClose = () => {
        setProgressModalOpen(false);
        if (onUserRemoved) {
            onUserRemoved(user.id);
        }
    };

    return (
        <>
            <tr className='user-list-item' onClick={() => handleUserClick(user.id)} style={{ cursor: 'pointer' }}>
                <td className="truncate"><strong>{user.person.name}</strong></td>
                <td className="truncate">{user.email}</td>
                <td>{formatCPF(user.person.cpf)}</td>
                <td>
                    {{
                        ADMIN: "Administrador",
                        MANAGER: "Gerente",
                        USER: "Usuário",
                    }[user.role] || "Desconhecido"}
                </td>
                <td>
                    <button onClick={handleMenuClick} className="button-ponto">...</button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <MenuItem onClick={handleEdit}>Editar</MenuItem>
                        <MenuItem onClick={handleOpenModal}>Remover</MenuItem>
                    </Menu>
                </td>
            </tr>

            <CustomConfirmationModal
                open={modalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmRemove}
                title="Tem certeza que deseja remover este usuário?"
                subtitle="Os dados do usuário serão perdidos."
                confirmText="Sim"
                cancelText="Cancelar"
            />

            <CustomConfirmationModal
                open={progressModalOpen}
                onClose={handleProgressModalClose}
                onConfirm={handleProgressModalClose}
                title="Usuário removido com sucesso!"
                subtitle=""
                showProgress={true}
                confirmText="Fechar"
            />
        </>
    );
}

const formatCPF = (cpf) => {
    if (!cpf) return '';
    const cleanCPF = cpf.replace(/\D/g, '');
    return cleanCPF.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
};

export default UsersListItem;
