import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import DashboardTabToggle from "../dashboard/DashboardTabToggle";
import BarChartComponent from "../dashboard/BarChartComponent";
import LineChartComponent from "../dashboard/LineChartComponent";
import CardChartComponent from "../dashboard/CardChartComponent";
import DateRangeFilter from "../filters/DateRangeFilter";
import SelectFilter from "../filters/SelectFilter";
import UserDropdown from "../users/UserDropdown";

import {
  getPatientDashboardData,
  getMostCommonItemData,
  getRegisterPerMonthData,
} from "../../services/dashboardService";

import "./PatientDashboard.css";

import CircularProgress from "@mui/material/CircularProgress";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import Box from "@mui/material/Box";

function PatientDashboard({ patientId }) {
  const { userToken } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);
  const [activeTab, setActiveTab] = useState("Doenças");
  const [isLoading, setIsLoading] = useState(false);

  const [currentSelectedDoenca, setCurrentSelectedDoenca] = useState(null);
  const [currentSelectedMedicamento, setCurrentSelectedMedicamento] =
    useState(null);
  const [currentSelectedProcedimento, setCurrentSelectedProcedimento] =
    useState(null);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);

  const [doencaOptions, setDoencaOptions] = useState([]);
  const [medicamentoOptions, setMedicamentoOptions] = useState([]);
  const [procedimentoOptions, setProcedimentoOptions] = useState([]);

  const tabs = ["Doenças", "Medicamentos", "Procedimentos"];

  const fetchDashboardData = async (
    tab = activeTab,
    selectedDoenca,
    selectedMedicamento,
    selectedProcedimento,
    startDateFilter,
    endDateFilter
  ) => {
    setIsLoading(true);
    try {
      const data = await getPatientDashboardData(
        userToken,
        patientId,
        tab,
        selectedDoenca,
        selectedMedicamento,
        selectedProcedimento,
        startDateFilter,
        endDateFilter
      );
      setDashboardData(data);
      updateFilters(data);
    } catch (error) {
      console.error("Erro ao carregar os dados do dashboard:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    fetchDashboardData(tabName);
  };

  const handleBarClick = (item) => {
    cleanFilter(false);

    let selectedDoenca = currentSelectedDoenca;
    let selectedMedicamento = currentSelectedMedicamento;
    let selectedProcedimento = currentSelectedProcedimento;

    if (activeTab === "Doenças") {
      setCurrentSelectedDoenca(item);
      selectedDoenca = item;
    } else if (activeTab === "Procedimentos") {
      setCurrentSelectedProcedimento(item);
      selectedProcedimento = item;
    } else if (activeTab === "Medicamentos") {
      setCurrentSelectedMedicamento(item);
      selectedMedicamento = item;
    }

    fetchDashboardData(
      activeTab,
      selectedDoenca,
      selectedMedicamento,
      selectedProcedimento,
      currentStartDate,
      currentEndDate
    );
  };

  const filter = () => {
    fetchDashboardData(
      activeTab,
      currentSelectedDoenca,
      currentSelectedMedicamento,
      currentSelectedProcedimento,
      currentStartDate,
      currentEndDate
    );
  };

  const cleanFilter = (fetch = true) => {
    setCurrentSelectedDoenca(null);
    setCurrentSelectedMedicamento(null);
    setCurrentSelectedProcedimento(null);
    setCurrentStartDate(null);
    setCurrentEndDate(null);

    if (fetch) {
      fetchDashboardData(activeTab, null, null, null, null, null);
    }
  };

  const updateFilters = (data) => {
    updateFiltersOptions(data);
    setCurrentSelectedDoenca(data.doencaFilter);
    setCurrentSelectedMedicamento(data.medicamentoFilter);
    setCurrentSelectedProcedimento(data.procedimentoFilter);
    setCurrentStartDate(data.startDateFilter);
    setCurrentEndDate(data.endDateFilter);
  };

  const updateFiltersOptions = (data) => {
    const formatOptions = (options) =>
      options.map((option) => ({ value: option, label: option }));

    setDoencaOptions(formatOptions(data.doencaOptions));
    setProcedimentoOptions(formatOptions(data.procedimentoOptions));
    setMedicamentoOptions(formatOptions(data.medicamentoOptions));
  };

  const onFilterChangeDoenca = (newValue) => {
    setCurrentSelectedDoenca(newValue);
  };
  const onFilterChangeMedicamento = (newValue) => {
    setCurrentSelectedMedicamento(newValue);
  };
  const onFilterChangeProcedimento = (newValue) => {
    setCurrentSelectedProcedimento(newValue);
  };
  const onChangeStartDate = (newValue) => {
    setCurrentStartDate(newValue);
  };
  const onChangeEndDate = (newValue) => {
    setCurrentEndDate(newValue);
  };

  const getCardChartPatientsSubtitle = () => {
    let title = "com registro de ";
    const items = [];

    if (dashboardData.doencaFilter !== null) {
      items.push(dashboardData.doencaFilter);
    }
    if (dashboardData.medicamentoFilter !== null) {
      items.push(dashboardData.medicamentoFilter);
    }
    if (dashboardData.procedimentoFilter !== null) {
      items.push(dashboardData.procedimentoFilter);
    }

    if (items.length > 1) {
      title += items.slice(0, -1).join(", ") + " e " + items[items.length - 1];
    } else if (items.length === 1) {
      title += items[0];
    } else {
      if (activeTab === "Doenças") {
        return "com registro de doenças";
      } else if (activeTab === "Procedimentos") {
        return "com registro de procedimentos";
      } else if (activeTab === "Medicamentos") {
        return "com registro de medicamentos";
      }
    }

    return title;
  };

  useEffect(() => {
    fetchDashboardData(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dashboardData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="patient-dashboard-main-content">
      {isLoading && (
        <div className="loader-overlay">
          <CircularProgress className="spinner" />
        </div>
      )}

      <div className="patient-dashboard-title-container">
        <h3 className="patient-dashboard-title">Dashboard do paciente</h3>
        <UserDropdown></UserDropdown>
      </div>

      {/* Filters */}
      <div className="card filters-card">
        <div className="filter-title-container">
          <div>
            <h5 className="filter-title">Filtros</h5>
          </div>
          <DashboardTabToggle
            tabs={tabs}
            activeTab={activeTab}
            onTabClick={handleTabClick}
          />
        </div>
        <div className="row filters-container">
          <SelectFilter
            title={"Doença"}
            currentSelected={currentSelectedDoenca}
            options={doencaOptions}
            onChange={onFilterChangeDoenca}
            isClearable={true}
            isMulti={true}
          ></SelectFilter>

          <SelectFilter
            title={"Medicamento"}
            currentSelected={currentSelectedMedicamento}
            options={medicamentoOptions}
            onChange={onFilterChangeMedicamento}
            isClearable={true}
            isMulti={true}
          ></SelectFilter>

          <SelectFilter
            title={"Procedimento"}
            currentSelected={currentSelectedProcedimento}
            options={procedimentoOptions}
            onChange={onFilterChangeProcedimento}
            isClearable={true}
            isMulti={true}
          ></SelectFilter>

          <DateRangeFilter
            title={"Período"}
            onChangeStart={onChangeStartDate}
            onChangeEnd={onChangeEndDate}
            startDate={currentStartDate}
            endDate={currentEndDate}
          />

          <div className="filter-btn-container col-6 col-sm-6 col-md-6 col-lg-1">
            <div className="btn btn-primary filter-btn" onClick={filter}>
              Filtrar
            </div>
          </div>
          <div className="filter-btn-container col-6 col-sm-6 col-md-6 col-lg-1">
            <div className="btn btn-primary filter-btn" onClick={cleanFilter}>
              Limpar
            </div>
          </div>
        </div>
      </div>

      <div className="patient-dashboard-charts">
        {/* Most common graph */}
        <div className="card patient-most-common-graph-card">
          <div className="patient-graph-title-container">
            <Box style={{ color: "#0A91FF" }}>
              <TravelExploreIcon />
            </Box>
            <div className="patient-graph-title">{activeTab} mais frequentes</div>
          </div>
          <BarChartComponent
            options={getMostCommonItemData(dashboardData)}
            onBarClick={handleBarClick}
          />
        </div>

        {/* Quantity graphs */}
        <div className="patient-quantity-graph-card">
          <CardChartComponent
            title={"Total de casos registrados"}
            subtitle={getCardChartPatientsSubtitle()}
            value={dashboardData.totalCasesQuantity
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          />
        </div>

        {/* Per month graph */}
        <div className="card patient-time-graph-card">
          <div className="patient-graph-title-container">
            <Box style={{ color: "#5654D4" }}>
              <TroubleshootIcon />
            </Box>
            <div className="graph-title">
              Quantidade de casos ao longo do tempo{" "}
              {getCardChartPatientsSubtitle()}
            </div>
          </div>
          <LineChartComponent
            options={getRegisterPerMonthData(dashboardData)}
            onBarClick={handleBarClick}
          />
        </div>
      </div>
    </div>
  );
}

export default PatientDashboard;
