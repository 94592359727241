import React from 'react';
import ChatRoute from './components/routes/ChatRoute';
import Login from './components/Login';
import ProtectedRoute from './components/routes/ProtectedRoute';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { MessagesProvider } from './contexts/MessageProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardRoute from './components/routes/DashboardRoute';
import PatientDashboardRoute from './components/routes/PatientDashboardRoute';
import UsersRoute from './components/routes/UsersRoute';
import UserFormRoute from './components/routes/UserFormRoute';

function App() {
  return (
    <AuthProvider>
      <MessagesProvider>
        <Router>
          <div>
            <Routes>
              <Route path="/login" element={<Login />} />

              <Route element={<ProtectedRoute allowedRoles={['USER', 'ADMIN', 'MANAGER']} />}>
                <Route path="/user/profile" element={<UserFormRoute mode="profile" />} />
                <Route path="/user/edit/profile" element={<UserFormRoute mode="editProfile" />} />
              </Route>

              <Route element={<ProtectedRoute allowedRoles={['USER', 'ADMIN']} />}>
                <Route path="/chat/new" element={<ChatRoute />} />
                <Route path="/chat/:chatId" element={<ChatRoute />} />
                <Route path="/dashboard/:patientId" element={<PatientDashboardRoute />} />
              </Route>

              <Route element={<ProtectedRoute allowedRoles={['MANAGER', 'ADMIN']} />}>
                <Route path="/dashboard" element={<DashboardRoute />} />
              </Route>

              <Route element={<ProtectedRoute allowedRoles={['ADMIN']} />}>
                <Route path="/user/list" element={<UsersRoute />} />
                <Route path="/user/form" element={<UserFormRoute mode="create" />} />
                <Route path="/user/edit/:userId" element={<UserFormRoute mode="edit" />} />
                <Route path="/user/view/:userId" element={<UserFormRoute mode="view" />} />
              </Route>

              <Route path="/" element={<Navigate to="/login" />} />
              
              <Route path="/unauthorized" element={<div>Unauthorized Access</div>} />
            </Routes>
          </div>
        </Router>
      </MessagesProvider>
    </AuthProvider>
  );
}

export default App;
