import React from 'react';
import Select from 'react-select';
import { FaCheck } from 'react-icons/fa';

const CustomOption = (props) => {
  const { data, isSelected, innerRef, innerProps } = props;
  
  return (
    <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
      {data.label}
      {isSelected && <FaCheck style={{ marginLeft: 'auto', minWidth: '20px', color: 'green' }} />}
    </div>
  );
};

const SelectFilter = ({ title, options, onChange, currentSelected, isClearable, isMulti }) => {
  
  const sortedOptions = [...options].sort((a, b) => {
    const aSelected = currentSelected?.includes(a.value) ? -1 : 1;
    const bSelected = currentSelected?.includes(b.value) ? -1 : 1;
    return aSelected - bSelected;
  });

  return (
    <div className="mb-3 filter col-sm-12 col-md-6 col-lg-2">
      <label className="form-label">{title}</label>
      <Select
        options={sortedOptions}
        value={options.filter(option => currentSelected?.includes(option.value))}
        onChange={(selected) => onChange(selected.map(option => option.value))}
        placeholder={(currentSelected?.length || 0) > 0 ? "Marcadas" : "Selecione"}
        className="basic-single"
        classNamePrefix="select"
        isClearable={isClearable}
        isMulti={isMulti}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        components={{ Option: CustomOption }}
      />
    </div>
  );
};

export default SelectFilter;
